import FormPage from '@/components/FormPage'
import { createPostParams } from '@/rest'
import security from '@/security'

const formTitle = 'Purchase Black PIN'

export default {
  extends: FormPage,
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: 'Black', disabled: false, to: '/black_kits',
        },
        {
          text: 'Black PIN', disabled: false, to: '/black_pin_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      title: formTitle,
      // Form steps
      submission: {
        fields: {
          pin: {
            component: () => import(/* webpackChunkName: "blackPinsField" */ '@/components/BlackPinsField'),
            name: 'Pin',
            value: { text: 'B89DB', value: 'B89DB' },
          },
          quantity: {
            component: () => import(/* webpackChunkName: "quantityField" */ '@/components/QuantityField'),
            name: 'Purchase quantity',
            props: {
              hint: 'Minimum 1',
              label: 'Enter quantity',
              maxlength: 6,
              required: true,
              rules: [
                val => Number(val) >= 1 || 'Minimum 1!',
              ],
            },
          },
          purchaseNote: {
            component: () => import(/* webpackChunkName: "textArea" */ '@/components/TextArea'),
            name: 'Purchase note',
            props: {
              label: 'Enter purchase note',
              multiLine: true,
              required: true,
              rules: [
                val => !!val || 'Purchase note required!',
              ],
            },
          },
          payTo: {
            name: 'Pay to',
            value: security.me.claimer() === 'xox' ? 'XOX Mobile Sdn. Bhd. (Hong Leong Bank Account: 071-01-01002-6)'
              : 'Maybank (5622-3667-9813) ONE XOX SDN BHD',
          },
        },
        submit: false,
        errorMessage: null,
        status: null,
        subTitle: 'Check before submit',
        title: 'Purchase Black Pin',
      },
    }
  },
  computed: {
    // Override
    formSteps: function () {
      return {
        submission: this.submission,
      }
    },
    quantity: function () {
      const quantity = this.submission.fields.quantity.value
      return Number.isNaN(quantity) ? 0 : Number(quantity)
    },
  },
  methods: {
    async submit () {
      const action = 'purchaseBlackPin'
      const params = createPostParams({
        action: action,
        payload: {
          type: this.submission.fields.pin.value.value,
          quantity: this.quantity,
          note: this.submission.fields.purchaseNote.value,
        },
      })

      try {
        const response = await this.$rest.post('postAction.php', params)

        this.$nextTick(() => {
          this.alertText = 'Your order to purchase ' + this.submission.fields.pin.value.value + ' pin was successfully submitted for processing.'
          this.status = this.submission.status = 'success'
          this.submission.submit = false
          this.isImpersonated() && this.showSubmissionResponse(response.data)
        })
      } catch (e) {
        this.$nextTick(() => {
          this.status = this.submission.status = 'error'
          this.submission.errorMessage = e.message + ': ' + e.response.data
          this.submission.submit = false
        })
      }
    },
  },
}
